import { Question } from "@/types/Form";

export const getNestedValue = (
  path: string,
  values: Record<string, any>
): any => {
  return path
    .split(".")
    .reduce((acc, key) => (acc ? acc[key] : undefined), values);
};

export const getNestedValueString = (
  path: string,
  values: Record<string, any>
): string => {
  const value = getNestedValue(path, values);

  if (typeof value === "number") {
    return value.toString();
  }

  if (typeof value === "boolean") {
    return value ? "Sim" : "Não";
  }

  if (Array.isArray(value)) {
    return value.join(", ");
  }

  return value;
};

export const checkVisibility = (
  question: Question,
  values: Record<string, any>
): boolean => {
  const visibleIfConditions = question.visibleIf;

  if (!visibleIfConditions) return true;

  const conditionsArray = Array.isArray(visibleIfConditions)
    ? visibleIfConditions
    : [visibleIfConditions];

  return conditionsArray.every((visibleIf) => {
    const parentValue = getNestedValue(visibleIf?.questionId || "", values);
    const { selectedOptionsInclude, selectedOptionsExclude, equals } =
      visibleIf;

    let conditionMet = true;

    if (selectedOptionsInclude) {
      if (Array.isArray(parentValue)) {
        conditionMet = selectedOptionsInclude.some((option) =>
          parentValue.includes(option)
        );
      } else {
        conditionMet = selectedOptionsInclude.includes(parentValue);
      }
    }

    if (selectedOptionsExclude) {
      if (Array.isArray(parentValue)) {
        conditionMet =
          conditionMet &&
          !selectedOptionsExclude.some((option) =>
            parentValue.includes(option)
          );
      } else {
        conditionMet =
          conditionMet && !selectedOptionsExclude.includes(parentValue);
      }
    }

    if (equals !== undefined) {
      conditionMet = conditionMet && parentValue === equals;
    }

    return conditionMet;
  });
};
